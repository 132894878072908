.news-ticker {
  width: 100%;
  overflow: hidden;
}

.ticker-text {
  white-space: nowrap;
  animation: scroll 60s linear infinite;
  /* display: inline-block; Added this line to make the text loop smoothly */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-350%);
  }
}
